import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
import { graphql } from "gatsby";
import { AccordionsBasic, AccordionsBorderless, AccordionsList, AccordionsSmall } from "../../examples/components/Accordions";
export const query = graphql`
  query AccordionQuery {
    Accordion: componentMetadata(displayName: { eq: "Accordion" }) {
      ...ComponentApi_metadata
    }
    AccordionToggle: componentMetadata(displayName: { eq: "AccordionToggle" }) {
      ...ComponentApi_metadata
    }
    AccordionCollapse: componentMetadata(
      displayName: { eq: "AccordionCollapse" }
    ) {
      ...ComponentApi_metadata
    }
  }
`;
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Overview = makeShortcode("Overview");
const CodeBlock = makeShortcode("CodeBlock");
const LinkedHeading = makeShortcode("LinkedHeading");
const ComponentApi = makeShortcode("ComponentApi");
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Overview mdxType="Overview">
  Accordions use <code>Card</code> components to provide styling of the
  <code> Accordion</code> components. Use <code>Accordion.Toggle</code> to provide
  a button that switches between each <code>Accordion.Collapse</code> component.
    </Overview>
    <CodeBlock bigtitle="Examples" mdxType="CodeBlock">
  If you want your Accordion to start in a fully-collapsed state, then simply
  don't pass in a <code>defaultActiveKey</code> prop to <code>Accordion</code>.
    </CodeBlock>
    <CodeBlock title="Collapsible Cards" code={AccordionsBasic} mdxType="CodeBlock"></CodeBlock>
    <CodeBlock title="Borderless Variant" code={AccordionsBorderless} mdxType="CodeBlock">
  Add the <code>.borderless </code>class to the Accordion component to create a
  borderless variant.
    </CodeBlock>
    <CodeBlock title="Small Variant" code={AccordionsSmall} mdxType="CodeBlock">
  Add the <code>.accordion-sm</code> class to the Accordion component to create
  a smaller variant.
    </CodeBlock>
    <CodeBlock title="Collapsible Lists" code={AccordionsList} mdxType="CodeBlock">
  You can use Accordion combination with other UI Elements to create collapsible
  content.
    </CodeBlock>
    <LinkedHeading h="2" className="h1" id="accordions-api" mdxType="LinkedHeading">
  API
    </LinkedHeading>
    <ComponentApi metadata={props.data.Accordion} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AccordionToggle} exportedBy={props.data.Accordion} mdxType="ComponentApi" />
    <ComponentApi metadata={props.data.AccordionCollapse} exportedBy={props.data.Accordion} mdxType="ComponentApi" />


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      